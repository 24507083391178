
import { Options, Vue, Watch, Prop } from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import LogsView from "@views/page/logs-view.vue";
import ViewFilter from "@views/page/view-filter.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import EnumLogLevel from "@/dataSource/api/Tirscript/LoggerMicroService/Enums/EnumLogLevel";

@Options({
  name: "LogsViewErrorPages",
  components: {
    Page,
    ViewFilter,
    LogsView,
  },
})
export default class LogsViewErrorsPage extends Vue {
  @Prop({ default: null }) slotProps: {
    sourceLogId: Number;
    filterChange: Boolean;
  };

  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = null;
  getUtc = getUtc;
  sourceId = -1;

  mounted() {
    this.filterModel.LogLevel = EnumLogLevel.Error;
  }

  setSourceId(value: number) {
    this.sourceId = value;
  }

  onclickFilterChange() {
    this.filterModelView = new FilterModel();
    this.filterModel.source = localStorage.source;

    const tmp: FilterModel = JSON.parse(JSON.stringify(this.filterModel));
    this.filterModelView.ProjectLogGroupId = tmp.ProjectLogGroupId;
    this.filterModelView.RequestId = tmp.RequestId;
    this.filterModelView.EndpointId = tmp.EndpointId;
    this.filterModelView.DateTo = tmp.DateTo;
    this.filterModelView.DateFrom = tmp.DateFrom;
    this.filterModelView.SessionToken = tmp.SessionToken;
    this.filterModelView.LogLevel = EnumLogLevel.Error;
    this.filterModelView.Ip = tmp.Ip;
    this.filterModelView.UserId = tmp.UserId;
    this.filterModelView.ConnectionId = tmp.ConnectionId;
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }
  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
}
